/* eslint-disable max-len */
import React from 'react';
import { Link } from 'gatsby';
import Layout from '../layouts';
import SEO from '../components/seo';
import '../styles/Components/terms.scss';
import StarWrapper from '../components/Common/StarWrapper';

const TermsOfService = () => (
  <Layout>
    <SEO title="Terms Of Service" />
    <div className="top-container">
      <StarWrapper />
      <h1>Reach Growth, Inc.</h1>
      <h2>Terms of Service</h2>
    </div>
    <div className="terms-container">
      <p><i>Updated July 20th, 2020</i></p>
      <div>
        <p>1.&nbsp;&nbsp;&nbsp;&nbsp;
          <strong>Acceptance of Terms.</strong>
        </p>
        <p>
          1.&nbsp;&nbsp;&nbsp;&nbsp;
          Reach Growth, Inc. (“Company” or “we”) provides its Service (as defined
          below) to you through its web site located at http://www.ReachSocialGrowth.com (the “Site”)
          or on any of its mobile applications (each a “Mobile App”), subject to this
          Terms of Service agreement (“TOS”).&nbsp; By
          accepting this TOS or by accessing or using the Service, Site, or Mobile App,
          you acknowledge that you have read, understood, and agree to be bound by this
          TOS.&nbsp; If you are entering into this TOS
          on behalf of a company, business or other legal entity, you represent that you
          have the authority to bind such entity and its affiliates to this TOS, in which
          case the terms “you” or “your” shall refer to such entity and its affiliates.
          If you do not have such authority, or if you do not agree with this TOS, you
          must not accept this TOS and may not use the Service.
        </p>
        <p>
          2.&nbsp;&nbsp;&nbsp;&nbsp;
          Company may change this TOS from time to time by providing thirty
          (30) days prior notice either by emailing the email address associated with
          your account or by posting a notice on the Site.&nbsp; You can review the most current version of
          this TOS at any time [at http://www.ReachSocialGrowth.com/terms-of-service].
          &nbsp; The
          revised terms and conditions will become effective thirty (30) days after we
          post or send you notice of such changes, and if you use the Service after that
          date, your use will constitute acceptance of the revised terms and
          conditions.&nbsp; If any change to this TOS is
          not acceptable to you, your only remedy is stop using the Services and send a
          cancellation email to rhys@reachsocialgrowth.com.</p><p>3.&nbsp;&nbsp;&nbsp;&nbsp;
          As part of the registration process, you will identify an
          administrative user name and password for your account (“Account”).&nbsp; 
        </p>
        <p>
          2.&nbsp;&nbsp;&nbsp;&nbsp;
          <strong>Description of Service.</strong>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 
          The “Service” includes (a) the Site, (b) any Mobile App and (c) all software (including the Software, as defined below),
          data, reports, text, images, sounds, video, and content made available through
          any of the foregoing (collectively referred to as the “Content”), which allow
          you to monitor activity, trends, and followers on your social media and/or
          social networking accounts such as Facebook, Twitter, Instagram, Pintrest, or Youtube (“Social
          Media Accounts”), communicate with your community and followers.&nbsp; Any new
          features added to or augmenting the Service are also subject to this TOS.&nbsp;
        </p>
        <p>
          3.&nbsp;&nbsp;&nbsp;&nbsp;
          <strong>
            General Conditions/ Access and Use of the Service.
          </strong>
        </p>
        <p>
          1.&nbsp;&nbsp;&nbsp;&nbsp;
          Subject to the terms and conditions of this TOS, you may access
          and use the Service only for lawful purposes.&nbsp;
          All rights, title and interest in and to the Service and its components
          will remain with and belong exclusively to Company.&nbsp; You shall not (a) sublicense, resell, rent,
          lease, transfer, assign, time share or otherwise commercially exploit or make
          the Service available to any third party; (b) use the Service in any unlawful
          manner (including without limitation in violation of any data, privacy or
          export control laws) or in any manner that interferes with or disrupts the
          integrity or performance of the Service or its components; (c) use the Service
          to provide services to third parties, unless expressly permitted by Company, or
          (d) modify, adapt or hack the Service to, or otherwise attempt to gain
          unauthorized access to the Service or its related systems or networks, take any
          actions which would disable the Service or impair in any way its operation, or
          access all or any part of the Service to build a product or service which
          competes with the Service.&nbsp; You shall
          comply with any codes of conduct, policies or other notices Company provides
          you or publishes in connection with the Service, and you shall promptly notify
          Company if you learn of a security breach related to the Service.
        </p>
        <p>
          2.&nbsp;&nbsp;&nbsp;&nbsp;
          Any software that may be made available by Company in connection
          with the Service (“Software”) contains proprietary and confidential information
          that is protected by applicable intellectual property and other laws.&nbsp; Subject to the terms and conditions of this
          TOS, Company hereby grants you a non-transferable, non-sublicensable and non-exclusive
          right and license to use the object code of any Software on a single device
          solely in connection with the Service, provided that you shall not (and shall
          not allow any third party to) copy, modify, create a derivative work of,
          reverse engineer, reverse assemble or otherwise attempt to discover any source
          code or sell, assign, sublicense or otherwise transfer any right in any
          Software.&nbsp; You agree not to access the
          Service by any means other than through the interface that is provided by
          Company for use in accessing the Service.&nbsp;
          Any rights not expressly granted herein are reserved and no license or
          right to use any trademark of Company or any third party is granted to you in
          connection with the Service.
        </p>
        <p>
          3.&nbsp;&nbsp;&nbsp;&nbsp;
          You are solely responsible for all data, information, feedback,
          suggestions, text, content and other materials that you upload, post, deliver,
          provide, make available by linking your Social Media Account(s) or otherwise
          transmit or store (hereafter “post(ing)”) in connection with or relating to the
          Service (“Your Content”).&nbsp; For every
          email sent outside of your organization via the Services, you acknowledge and
          agree that Company shall have the right to automatically add an identifying
          footer in accordance with our standard policies then in effect. You agree to
          cooperate with and provide reasonable assistance to Company in promoting and
          advertising the Services.
        </p>
        <p>
          4.&nbsp;&nbsp;&nbsp;&nbsp;
          You are responsible for maintaining the confidentiality of your
          login, password and account and for all activities that occur under your login
          or account.&nbsp; Company reserves the right to
          access your account in order to respond to your requests for technical
          support.&nbsp; By posting Your Content on or
          through the Service, you hereby do and shall grant Company a worldwide,
          non-exclusive, perpetual, irrevocable, royalty-free, fully paid, sublicensable
          and transferable license to use, modify, reproduce, distribute,&nbsp; display, publish and perform Your Content in
          connection with the Service. Company has the right, but not the obligation, to
          monitor the Service, Content, or Your Content.&nbsp;
          You further agree that Company may remove or disable any Content at any
          time for any reason (including, but not limited to, upon receipt of claims or
          allegations from third parties or authorities relating to such Content), or for
          no reason at all.
        </p>
        <p>
          5.&nbsp;&nbsp;&nbsp;&nbsp;
          You grant, subject to this TOS, to grand Company a limited,
          non-exclusive, non-transferable license to use yours trademarks, service marks,
          logos, and any other indicia of brand ownership (“Marks”) solely in connection
          with providing the services hereunder and publicizing the relationship between
          you and Company and in accordance with this Section. Company agrees that any
          use of your Marks: (i) will comply with any of your identity standards that you
          reasonably provides to Company; and (ii) will inure to the benefit of you. The
          Marks are proprietary and nothing in this TOS constitutes the grant of a
          general license for their use. Neither you nor Company acquires any right,
          title or interest in the other party’s Marks or the goodwill associated
          therewith
        </p>
        <p>
          6.&nbsp;&nbsp;&nbsp;&nbsp;
          You understand that the operation of the Service, including Your
          Content, may be unencrypted and involve (a) transmissions over various
          networks; (b) changes to conform and adapt to technical requirements of
          connecting networks or devices and (c) transmission to Company’s third party
          vendors and hosting partners to provide the necessary hardware, software,
          networking, storage, and related technology required to operate and maintain
          the Service.&nbsp; Accordingly, you
          acknowledge that you bear sole responsibility for adequate security, protection
          and backup of Your Content.&nbsp; Company will
          have no liability to you for any unauthorized access or use of any of Your
          Content, or any corruption, deletion, destruction or loss of any of Your
          Content.
        </p>
        <p>
          7.&nbsp;&nbsp;&nbsp;&nbsp;
          You shall be responsible for obtaining and maintaining any
          equipment and ancillary services needed to connect to, access or otherwise use
          the Services, including, without limitation, modems, hardware, server,
          software, operating system, networking, web servers, long distance and local
          telephone service (collectively, “Equipment”).&nbsp;
          You shall be responsible for ensuring that such Equipment is compatible
          with the Services (and, to the extent applicable, the Software) and complies
          with all configurations and specifications set forth in Company’s published
          policies then in effect.&nbsp; You shall also
          be responsible for maintaining the security of the Equipment, your Account,
          passwords (including but not limited to administrative and user passwords) and
          files, and for all uses of your Account or the Equipment with or without your
          knowledge or consent.
        </p>
        <p>
          8.&nbsp;&nbsp;&nbsp;&nbsp;
          Subject to the terms and conditions set forth in this TOS, Company
          hereby grants you the non-exclusive right and license to view, reproduce and
          analyze any data points or demographic information regarding any user of
          accounts within the Social Media Accounts collected, stored or processed by the
          Service, solely as provided by Company to you and solely for internal business
          purposes.
        </p>
        <p>
          9.&nbsp;&nbsp;&nbsp;&nbsp;
          The failure of Company to exercise or enforce any right or
          provision of this TOS shall not be a waiver of that right. You acknowledge that
          this TOS is a contract between you and Company, even though it is electronic
          and is not physically signed by you and Company, and it governs your use of the
          Service.
        </p>
        <p>
          10.&nbsp; Company
          reserves the right to use your name and/or company name as a reference for
          marketing or promotional purposes on Company’s website and in other
          communication with existing or potential Company customers. To decline Company
          this right you need to email info@reachsocialgrowth.com&nbsp;stating that you do not
          wish to be used as a reference. 
        </p>
        <p>
          11.&nbsp; Subject to
          the terms hereof, Company may (but has no obligation to) provide technical
          support services, through email in accordance with our standard practice.&nbsp; 
        </p>
        <p>
          12.&nbsp; &nbsp;Apple-Enabled
          Software Applications.&nbsp; Company offers
          Software applications that are intended to be operated in connection with
          products made commercially available by Apple Inc. (“Apple”), among other
          platforms.&nbsp; With respect to Software that
          is made available for your use in connection with an Apple-branded product
          (such Software, “Apple-Enabled Software”), in addition to the other terms and
          conditions set forth in these TOS, the following terms and conditions apply:</p><p>• Company and you acknowledge that these TOS are concluded between
          Company and you only, and not with Apple, and that as between Company and
          Apple, Company, not Apple, is solely responsible for the Apple-Enabled Software
          and the content thereof.
        </p>
        <p>
          •&nbsp; You may not use the Apple-Enabled Software in any manner that is
          in violation of or inconsistent with the Usage Rules set forth for
          Apple-Enabled Software in, or otherwise be in conflict with, the App Store TOS.
        </p>
        <p>
          •&nbsp;Your license to use the Apple-Enabled Software is limited to a
          non-transferable license to use the Apple-Enabled Software on an iOS Product that
          you own or control, as permitted by the Usage Rules set forth in the App Store
          TOS.
        </p>
        <p>
          •&nbsp;&nbsp;Apple has no obligation whatsoever to provide any maintenance or
          support services with respect to the Apple-Enabled Software.
        </p>
        <p>
          •&nbsp;Apple is not responsible for any product warranties, whether
          express or implied by law. In the event of any failure of the Apple-Enabled
          Software to conform to any applicable warranty, you may notify Apple, and Apple
          will refund the purchase price for the Apple-Enabled Software to you, if any; and,
          to the maximum extent permitted by applicable law, Apple will have no other
          warranty obligation whatsoever with respect to the Apple-Enabled Software, or
          any other claims, losses, liabilities, damages, costs or expenses attributable
          to any failure to conform to any warranty, which will be Company’s sole
          responsibility, to the extent it cannot be disclaimed under applicable law.
        </p>
        <p>
          •&nbsp;Company and you acknowledge that Company, not Apple, is
          responsible for addressing any claims of you or any third party relating to the
          Apple-Enabled Software or your possession and/or use of that Apple-Enabled
          Software, including, but not limited to: (i) product liability claims; (ii) any
          claim that the Apple-Enabled Software fails to conform to any applicable legal
          or regulatory requirement; and (iii) claims arising under consumer protection
          or similar legislation.
        </p>
        <p>
          • In the event of any third party claim that the Apple-Enabled
          Software or the end-user’s possession and use of that Apple-Enabled Software
          infringes that third party’s intellectual property rights, as between Company
          and Apple, Company, not Apple, will be solely responsible for the
          investigation, defense, settlement and discharge of any such intellectual
          property infringement claim.
        </p>
        <p>
          •&nbsp;You represent and warrant that (i) you are not located in a
          country that is subject to a U.S. Government embargo, or that has been
          designated by the U.S. Government as a “terrorist supporting” country; and (ii)
          you are not listed on any U.S. Government list of prohibited or restricted
          parties.
        </p>
        <p>
          •&nbsp;If you have any questions, complaints or claims with respect to
          the Apple-Enabled Software, they should be directed to Company as follows
        </p>
        <p>
          info@reachsocialgrowth.com
        </p>
        <p>
          Company and you acknowledge and
          agree that Apple, and Apple’s subsidiaries, are third party beneficiaries of
          these TOS with respect to the Apple-Enabled Software, and that, upon your
          acceptance of the terms and conditions of these TOS, Apple will have the right
          (and will be deemed to have accepted the right) to enforce these TOS against you
          with respect to the Apple-Enabled Software as a third party beneficiary
          thereof.
        </p>
        <p>
          1.&nbsp;&nbsp;&nbsp;&nbsp;
          <strong>Payment.</strong>
          To the extent the Service or any portion thereof is made available for any fee, you
          {/* TODO: Subject to change when we are out of Alpha */}
          may be required to select a payment plan and provide Company information
          regarding your credit card or other payment instrument.&nbsp; You represent and warrant to Company that
          such information is true and that you are authorized to use the payment
          instrument.&nbsp; You will promptly update
          your account information with any changes (for example, a change in your
          billing address or credit card expiration date) that may occur.&nbsp; You agree to pay Company the amount that is
          specified in the payment plan in accordance with the terms of such plan and
          this TOS.&nbsp; You hereby authorize Company
          to bill your payment instrument in advance on a periodic basis in accordance
          with the terms of the applicable payment plan until you terminate your account,
          and you further agree to pay any charges so incurred.&nbsp; If you dispute any charges you must let
          Company know within sixty (60) days after the date that Company invoices you.
          We reserve the right to change Company’s prices. If Company does change prices,
          Company will provide notice of the change on the Site or in email to you, at
          Company’s option, at least 30 days before the change is to take effect. Your
          continued use of the Service after the price change becomes effective
          constitutes your agreement to pay the changed amount.&nbsp; Company may choose to bill through an
          invoice, in which case, full payment for invoices issued in any given month
          must be received by Company thirty (30) days after the mailing date of the
          invoice, or the Services may be terminated.&nbsp;
          Unpaid invoices are subject to a finance charge of 1.5% per month on any
          outstanding balance, or the maximum permitted by law, whichever is lower, plus
          all expenses of collection. You shall be responsible for all taxes associated
          with Services other than U.S. or Canadian taxes based on Company’s net income.
        </p>
        <p>
          <strong>2.&nbsp;&nbsp;&nbsp;&nbsp;</strong>
          <strong>Representations and Warranties.</strong>
          &nbsp; You represent and warrant to Company that (i)
          you have full power and authority to enter into this TOS; (ii) you own all Your
          Content or have obtained all permissions, releases, rights or licenses required
          to engage in your posting and other activities (and allow Company to perform
          its obligations) in connection with the Services without obtaining any further
          releases or consents; (iii) Your Content and other activities in connection
          with the Service, and Company’s exercise of all rights and license granted by
          you herein, do not and will not violate, infringe, or misappropriate any third
          party’s copyright, trademark, right of privacy or publicity, or other personal
          or proprietary right, nor does Your Content contain any matter that is
          defamatory, obscene, unlawful, threatening, abusive, tortious, offensive or
          harassing; and (iv) you are eighteen (18) years of age or older.
        </p>
        <p>
          3.&nbsp;&nbsp;&nbsp;&nbsp;
          <strong>Termination.</strong>&nbsp; You have the right to terminate your account
          at any time by sending a cancellation request to rhys@ReachSocialGrowth.com&nbsp; Subject to earlier termination as provided
          below, Company may terminate your Account and this TOS at any time by providing
          thirty (30) days prior notice to the administrative email address associated
          with your Account.&nbsp; In addition to any
          other remedies we may have, Company may also terminate this TOS upon thirty
          (30) days’ notice (or ten (10) days in the case of nonpayment), if you breach
          any of the terms or conditions of this TOS.&nbsp;
          Company reserves the right to modify or discontinue, temporarily or
          permanently, the Service (or any part thereof).&nbsp;
          All of Your Content on the Service (if any) may be permanently deleted
          by Company upon any termination of your account in its sole discretion.&nbsp; If Company terminates your account without
          cause and you have signed up for a fee-bearing service, Company will refund the
          pro-rated, unearned portion of any amount that you have prepaid to Company for
          such Service<strong><em>.</em></strong>&nbsp; However, all
          accrued rights to payment and the terms of Section 4-12 shall survive
          termination of this TOS.
        </p>
        <p>4.&nbsp;&nbsp;&nbsp;&nbsp;
          <strong>DISCLAIMER OF WARRANTIES.</strong>&nbsp; The Services may be temporarily unavailable
          for scheduled maintenance or for unscheduled emergency maintenance, either by
          Company or by third-party providers, or because of other causes beyond our
          reasonable control, but Company shall use reasonable efforts to provide advance
          notice in writing or by email of any scheduled service disruption.&nbsp; HOWEVER, THE SERVICE, INCLUDING THE SITE AND
          CONTENT, AND ALL SERVER AND NETWORK COMPONENTS ARE PROVIDED ON AN “AS IS” AND
          “AS AVAILABLE” BASIS WITHOUT ANY WARRANTIES OF ANY KIND, AND COMPANY EXPRESSLY
          DISCLAIMS ANY AND ALL WARRANTIES, WHETHER EXPRESS OR IMPLIED, INCLUDING, BUT
          NOT LIMITED TO, THE IMPLIED WARRANTIES OF MERCHANTABILITY, TITLE, FITNESS FOR A
          PARTICULAR PURPOSE, AND NON-INFRINGEMENT. YOU ACKNOWLEDGE THAT COMPANY DOES NOT
          WARRANT THAT THE SERVICE WILL BE UNINTERRUPTED, TIMELY, SECURE, ERROR-FREE OR
          VIRUS-FREE, NOR DOES IT MAKE ANY WARRANTY AS TO THE RESULTS THAT MAY BE
          OBTAINED FROM USE OF THE SERVICES, AND NO INFORMATION, ADVICE OR SERVICES OBTAINED
          BY YOU FROM COMPANY OR THROUGH THE SERVICE SHALL CREATE ANY WARRANTY NOT
          EXPRESSLY STATED IN THIS TOS.</p><p>5.&nbsp;&nbsp;&nbsp;&nbsp;
          <strong>LIMITATION OF LIABILITY.</strong>
        </p>
        <p>
          1.&nbsp;&nbsp;&nbsp;&nbsp;
          UNDER NO CIRCUMSTANCES AND UNDER NO LEGAL THEORY (WHETHER IN
          CONTRACT, TORT, OR OTHERWISE) SHALL COMPANY BE LIABLE TO YOU OR ANY THIRD PARTY
          FOR (A) ANY INDIRECT, INCIDENTAL, SPECIAL, EXEMPLARY, CONSEQUENTIAL OR PUNITIVE
          DAMAGES, INCLUDING LOST PROFITS, LOST SALES OR BUSINESS, LOST DATA, OR (B) FOR
          ANY DIRECT DAMAGES, COSTS, LOSSES OR LIABILITIES IN EXCESS OF THE FEES ACTUALLY
          PAID BY YOU IN THE SIX (6) MONTHS PRECEDING THE EVENT GIVING RISE to YOUR CLAIM
          or, IF NO FEES APPLY, one hundred ($100) U.S. dollars.&nbsp; THE PROVISIONS OF THIS SECTION ALLOCATE THE
          RISKS UNDER THIS TOS BETWEEN THE PARTIES, AND THE PARTIES HAVE RELIED ON THESE
          LIMITATIONS IN DETERMINING WHETHER TO ENTER INTO THIS TOS.
        </p>
        <p>
          2.&nbsp;&nbsp;&nbsp;&nbsp;
          Some states or provinces do not allow the exclusion of implied warranties or
          limitation of liability for incidental or consequential damages, which means
          that some of the above limitations may not apply to you. IN THESE STATES OR PROVINCES,
          COMPANY’S LIABILITY WILL BE LIMITED TO THE GREATEST EXTENT PERMITTED BY LAW.
        </p>
        <p>
          6.&nbsp;&nbsp;&nbsp;&nbsp;
          <strong>Indemnification.</strong>&nbsp; You shall defend, indemnify, and hold
          harmless Company from and against any claims, actions or demands, including
          without limitation reasonable legal and accounting fees, arising or resulting
          from your breach of this TOS, any of Your Content, or your other access,
          contribution to, use or misuse of the Service.&nbsp;
          Company shall provide notice to you of any such claim, suit or
          demand.&nbsp; Company reserves the right to
          assume the exclusive defense and control of any matter which is subject to
          indemnification under this section. In such case, you agree to cooperate with
          any reasonable requests assisting Company’s defense of such matter.
        </p>
        <p>
          7.&nbsp;&nbsp;&nbsp;&nbsp;
          <strong>U.S. &amp; Canadian Government Matters.</strong>&nbsp; You may not remove or export from the United
          States  or Canada or allow the export or re-export of the Services or anything related
          thereto, or any direct product thereof in violation of any restrictions, laws
          or regulations of the United States Department of Commerce, the United States
          Department of Treasury Office of Foreign Assets Control, or any other United
          States or foreign agency or authority.&nbsp;
          As defined in FAR section 2.101, the software and documentation installed
          by Company on your Equipment (if applicable) are “commercial items” and
          according to DFAR section 252.227 7014(a)(1) and (5) are deemed to be
          “commercial computer software” and “commercial computer software
          documentation.”&nbsp; Consistent with DFAR section
          227.7202 and FAR section 12.212, any use modification, reproduction, release,
          performance, display, or disclosure of such commercial software or commercial
          software documentation by the U.S. Government will be governed solely by the
          terms of this TOS and will be prohibited except to the extent expressly
          permitted by the terms of this TOS.<strong>&nbsp; </strong>
        </p>
        <p>
          8.&nbsp;&nbsp;&nbsp;&nbsp;
          <strong>Assignment.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </strong>You may not
          assign this TOS without the prior written consent of Company, but Company may
          assign or transfer this TOS, in whole or in part, without restriction.
        </p>
        <p>
          9.&nbsp;&nbsp;&nbsp;&nbsp;
          <strong>Miscellaneous.&nbsp;&nbsp;&nbsp; </strong>If any provision of this TOS is
          found to be unenforceable or invalid, that provision will be limited or
          eliminated to the minimum extent necessary so that this TOS will otherwise
          remain in full force and effect and enforceable.&nbsp; Both parties agree that this TOS, is the
          complete and exclusive statement of the mutual understanding of the parties and
          supersedes and cancels all previous written and oral agreements, communications
          and other understandings relating to the subject matter of this TOS, and that
          all waivers and modifications must be in a writing signed by both parties,
          except as otherwise provided herein.&nbsp; No
          agency, partnership, joint venture, or employment is created as a result of
          this TOS and you do not have any authority of any kind to bind Company in any respect
          whatsoever.&nbsp; In any action or proceeding
          to enforce rights under this TOS, the prevailing party will be entitled to
          recover costs and attorneys’ fees.&nbsp; All
          notices under this TOS will be in writing and will be deemed to have been duly
          given when received, if personally delivered; when receipt is electronically
          confirmed, if transmitted by facsimile or email; the day after it is sent, if
          sent for next day delivery by recognized overnight delivery service; and upon
          receipt, if sent by certified or registered mail, return receipt requested.
        </p>
        <p>
          10.&nbsp; <strong>Governing
          Law.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </strong>This TOS
          shall be governed by the laws of the province of British Columbia without regard to the
          principles of conflicts of law. Unless otherwise elected by Company in a
          particular instance, you hereby expressly agree to submit to the exclusive
          personal jurisdiction of the federal and provincial courts of the province of British Columbia
          for the purpose of resolving any dispute relating to your access to or use of
          the Service.
        </p>
        <p>
          11.&nbsp; <strong>Privacy.&nbsp; </strong>Please visit www.reachsocialgrowth.com/privacy-policy
        to understand how Company collects and uses personal information.&nbsp; 
        </p>
        <p>
          12.&nbsp; <strong>DMCA.&nbsp; </strong>The Digital Millennium Copyright
          Act of 1998 (the “DMCA”) provides recourse for copyright owners who believe
          that material appearing on the Internet infringes their rights under U.S.
          copyright law.&nbsp; Company will promptly
          process and investigate notices of alleged infringement and will take
          appropriate actions under the DMCA and other applicable intellectual property
          laws with respect to any alleged or actual infringement.&nbsp; A notification of claimed copyright
          infringement should be emailed to Company’s Copyright Agent at rhys@reachsocialgrowth.com
          (subject line:&nbsp; “DMCA” Takedown
          Request”).&nbsp; You may also contact us by
          mail or facsimile at:</p><p>Attention:&nbsp; Copyright Agent
        </p>
        <p>
          Company
        </p>
        <p>
          info@reachsocialgrowth.com
        </p>
        <p>
          12974 19 Avenue, V4A 8P2, Surrey, British Columbia
        </p>
        <p>&nbsp;</p>
        <p>
          <strong>Notice: </strong>To be
          effective, the notification must be in writing and contain the following
          information:</p>
        <p>
          •&nbsp; &nbsp;an electronic or physical signature of the person authorized to
          act on behalf of the owner of the copyright or other intellectual property
          interest;
        </p>
        <p>
          •&nbsp; a description of the copyrighted work or other intellectual
          property that you claim has been infringed;
        </p>
        <p>
          •&nbsp;&nbsp;a description of where the material that you claim is infringing
          is located on the Site, with enough detail that we may find it on the Site;
        </p>
        <p>
          •&nbsp;your address, telephone number, and email address;
        </p>
        <p>
          •&nbsp; a statement by you that you have a good faith belief that the
          disputed use is not authorized by the copyright or intellectual property owner,
          its agent, or the law;
        </p>
        <p>
          •&nbsp; a statement by you, made under penalty of perjury, that the above
          information in your Notice is accurate and that you are the copyright or
          intellectual property owner or authorized to act on the copyright or
          intellectual property owner‘s behalf.
        </p><p>&nbsp;</p>
        <p>
          <strong>Counter-Notice</strong>: If you
          believe that the relevant Content that was removed (or to which access was
          disabled) is not infringing, or that you have the authorization from the
          copyright owner, the copyright owner’s agent, or pursuant to the law, to post
          and use such Content, you may send a written counter-notice containing the
          following information to the Copyright Agent:
        </p>
        <p>
          •&nbsp; your physical or electronic signature;
        </p>
        <p>
          • identification of the content that has been removed or to which
        access has been disabled and the location at which the content appeared before it
        was removed or disabled;
        </p>
        <p>
          •&nbsp;a statement that you have a good faith belief that the content was
          removed or disabled as a result of mistake or a misidentification of the
          content; and
        </p>
        <p>
          •&nbsp;your name, address, telephone number, and e-mail address, a
          statement that you consent to the jurisdiction of the federal court located
          within the Northern District of California and a statement that you will accept
          service of process from the person who provided notification of the alleged
          infringement. 
        </p>
        <p>
          If a counter-notice is received by
          the Copyright Agent, Company will send a copy of the counter-notice to the
          original complaining party informing that person that it may replace the
          removed content or cease disabling it in 10 business days. Unless the copyright
          owner files an action seeking a court order against the content provider,
          member or user, the removed content may be replaced, or access to it restored,
          in 10 to 14 business days or more after receipt of the counter-notice, at
          Company’s sole discretion.
        </p>
        <p>
          <strong>Repeat Infringer Policy</strong>:&nbsp; In accordance with the DMCA and other
          applicable law, Company has adopted a policy of terminating, in appropriate
          circumstances and at Company 's sole discretion, members who are deemed to be
          repeat infringers.&nbsp; Company may also at
          its sole discretion limit access to the Site and/or terminate the memberships
          of any users who infringe any intellectual property rights of others, whether
          or not there is any repeat infringement.
        </p>
      </div>
    </div>
  </Layout>
);

export default TermsOfService;
